:root {
  /*--main-theme-color: rgb(64, 19, 107);
  --main-div-background-color: rgb(246, 236, 254);
  --theme-div-background-dark-4: rgb(198, 138, 255);
  --theme-div-background-light-2: rgb(156, 92, 215);
  --theme-div-background-light-1: rgb(246, 243, 248);*/

  --theme-button-primary-color: rgb(224, 187, 55);
  --theme-button-secondary-color: rgb(238, 230, 201);
  --theme-button-border-color: rgb(114, 89, 1);
  --theme-button-border-color-lighter: rgb(201, 193, 165);
  --theme-button-on-hover-color: rgb(114, 89, 1);
  --theme-button-on-active-color: rgb(45, 35, 0);
  --theme-button-font-size: 1.1rem;
  --theme-button-font-size-small: 0.6rem;
  --theme-button-font-weight: bold;
  --theme-button-font-color: white;
  --theme-error-font-color: rgb(252, 53, 53);
 /*
  --theme-service-parent-background: rgb(133, 12, 245);
  --theme-service-child-background: rgb(219, 188, 248);
  --theme-service-parent-border-color: rgb(92, 1, 177);
  --theme-service-child-border-color: rgb(180, 111, 245);
  --theme-service-child-shadow-color: rgba(47, 2, 89, 0.257);
  --theme-service-on-hover-color: rgb(179, 122, 233);
  --theme-service-on-active-color: rgb(64, 19, 107);
  --theme-service-parent-font-size: 80px;
  --theme-service-child-font-weight: bold;
  --theme-service-child-font-size: 60px;
  --theme-service-child-font-weight: bold;

  --theme-page-title-color: rgb(64, 19, 107);

  --theme-footer-background-color-dark: rgb(32, 5, 58);
  --theme-footer-background-color-light: rgb(224, 216, 231);
*/
  --theme-font-size-small: 0.9rem;
  --theme-font-size-medium: 1rem;
  --theme-font-size-normal: 1.1rem;
  --theme-font-size-slightly-bigger: 1.4rem;
  --theme-font-size-big: 1.8rem;

  --theme-arabic-font-family: "Tajawal", sans-serif;
  --theme-english-font-family: "Inria Sans", sans-serif;
  --theme-font-weight-1: 200;
  --theme-font-weight-2: 300;
  --theme-font-weight-3: 400;
  --theme-font-weight-4: 500;
  --theme-font-style-normal: normal;
  --theme-font-style-italic: italic;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*font-family: var(--theme-english-font-family);
  font-size: var(--theme-font-size-normal);*/
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  width: 60px;
  height: 40px;
  padding: 2px;
  margin: 6px;
  font-size: 2rem;
  background-color: var(--theme-button-secondary-color);
  border: 1px solid rgba(255, 255, 255, 0.149);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon:hover {
  background-color: rgb(136, 128, 118);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


