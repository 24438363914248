.navbar {
  position: relative;
  /*background-color: #d7f2da;
  box-shadow: 1px 5px 5px #c4efc9;*/
  background-color: #D6AE81;
  box-shadow: 0px 5px 5px #00797A;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: 'Changa', cursive;
  font-weight: 300;

  padding: 0px;
  margin: 0px;

  width: 100%;
  height: 80px;
  min-height: 6vh;
  box-sizing: border-box;

  transition: all 0.7s ease-in;
}

.scrolled {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
}

.burger {
  padding: 0;
  margin: 15px 1.2rem 0px 0px;
  border: none;
  display: flex;
}

.closeIcon {
  padding: 0;
  margin: 15px 1.2rem 0px 0px;
  border: none;
  display: flex;
}

.burger:hover {
  background-color: rgb(0, 121, 122, 0.7);
}

.closeIcon:hover {
  background-color: rgb(0, 121, 122, 0.7);
}

.navLogo {
  position: relative;
  margin-top: 0;
  margin-left: 10px;
  padding-right: 10px;
}

.navLogoImage{
  position: absolute;
  margin: 5px 5px 5px 0px;
  height: 70px;
  width:40px;
  border: 2px solid rgb(241, 210, 175, 0.5);
}

.navItems {
  list-style-type: none;
  padding: 0px;

  margin: 20px 40px 20px 40px;

  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.navItems li {
  margin: 10px;
}

.navItems li:hover {
  background-color: rgb(202, 100, 71);
}

.mobileNavMenu {
  display: flex;
  flex-direction: column;
  align-self: right;
  z-index: 1;
  padding-left: auto;
  padding-right: 0;
}
.mobileNavMenu ul {
  position: absolute;
  top: 48px;
  right: 0;

  width: 100%;
  height: 40%;
  flex-direction: column;
  align-self: right;
  background-color: rgb(255, 255, 255, 0.6);
  z-index: -1;
}
.mobileNavMenu li {
  text-align: center;
  size: 60px 80px;
  margin: 0;
  background-color: rgb(255, 255, 255, 0.6);
  border-bottom: 1px solid rgb(0, 121, 122, 0.7);
}
.mobileNavMenu li a {
  color: black;
  width: 100%;
  padding: 1.5rem 0;
}
.mobileNavMenu li:hover {
  background-color: rgb(0, 121, 122, 0.7);
}
