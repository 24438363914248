




.footer {
  position: relative;
  /*background-color: #d7f2da;
  box-shadow: 1px 5px 5px #c4efc9;*/
  background-color: #D6AE81;
  box-shadow: 0px -5px 5px #01b3b3;
  display: flex;
  flex-direction: row;


  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: 'Changa', cursive;
  font-weight: 300;

  
  padding: 0px;
  margin: 0px;

  width: 100%;
  height: 20px;
  min-height: 4vh;
  box-sizing: border-box;

  transition: all 0.7s ease-in;
}
