.hls {
    size: inherit;
}

.infoDiv {
    background-color: rgba(251, 251, 251, 0.55);
    border: 1px solid rgb(0, 121, 122, 0.7);
    border-radius: 4px;

    margin: 2px 8px 2px 8px;
    height: 35px;

    padding-top: 10px;
    font-size: 0.875rem;
    font-weight: 700;
    text-align: center;
}