.myButton {
    width: auto;
    height: fit-content;
    max-height: 100px;
    margin: 4px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color: var(--theme-button-font-color);
    font-weight: var(--theme-button-font-weight);
    background-color: var(--theme-button-primary-color);
    border: 1px solid var(--theme-button-border-color);
    border-radius: 1rem;
    box-shadow: 2px 4px rgba(0, 0, 0, 0.1);
    font-size: var(--theme-button-font-size);
    transition: transform 0.1s, box-shadow 0.2s;
  }
  
  .primary {
    background-color: var(--theme-button-primary-color);
  }
  
  .secondary {
    background-color: var(--theme-button-secondary-color);
  }
  
  .myButton:hover {
    background-color: var(--theme-button-on-hover-color);
    transform: translateY(2px);
    box-shadow: 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .myButton:active {
    color: var(--theme-button-on-active-color);
    outline: none; /* Remove default focus outline */
    box-shadow: inset 2px -2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .smallerButton {
    width: 60px;
    height: 30px;
    font-size: var(--theme-button-font-size-small);
  }
  
  .flashing {
    animation: flashAnimation 1.5s alternate infinite;
  }
  
  @keyframes flashAnimation {
    0% {
      background-color: initial;
    }
    100% {
      background-color: rgb(1, 144, 120); /* Change this to the color you want */
    }
  }