:root {
  --management-min-height: 600px;
}
.management_tab_container {
  min-height: var(--management-min-height);
  min-width: 900px;
  max-width: 80vw;
  margin-top: 20px;
  padding: 1% 2% 1% 2%;
  border: 1px solid rgba(35, 34, 34, 0.23);
  border-radius: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.p_arabic {
  text-align: justify;
  text-align-last: right;
  direction: rtl;
}

.p_english {
  text-align: justify;
  text-align-last: left;
}

.p_error {
  text-align: center;
  color: red;
  font-size: 1.2rem;
}

.container {
  width: 100%;
  height: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input_container {
  background-color: #cccccc52;
  width: 100%;
  height: 100%;
  margin: 10px 10px 10px 10px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(20, 1fr);
  gap: 10px;

  border-radius: 1rem;
  justify-content: center;
  align-items: center;
}

.input_selection_div {
  grid-row: 1 / 11;
  grid-column: 1 / 7;

  border: solid 2px #231f1f28;
  border-radius: 2%;

  min-height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20px;

  padding: 20% 2% 20% 2%;

  position: relative;
}

.div_title {
  position: absolute;
  top: 0;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  color: black;
  font-size: 1.2rem;
  font-weight: bold;
}

.primary_info_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.input_element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2%;
}

.settings_input_element {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 2%;
  margin-bottom: 60px;
}

.input_label {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--theme-font-size-medium);
  font-weight: bold;
}

.input_field {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input_field_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2%;
  width: 60%;
}

.item_description_div {
  grid-row: 1 / 11;
  grid-column: 7 / 13;

  background-color: #00ffff13;
  border: solid 2px #231f1f28;
  border-radius: 2%;

  min-height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  padding: 15% 5% 15% 5%;

  position: relative;
}

.description_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.image_display_div {
  grid-row: 11 / 21;
  grid-column: 1 / 7;

  background-color: rgba(246, 254, 208, 0.08);
  border: solid 2px #231f1f28;
  border-radius: 2%;

  min-height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  padding: 15% 5% 15% 5%;

  position: relative;
}

.extras_selection_div {
  grid-row: 11 / 21;
  grid-column: 7 / 13;

  background-color: rgba(151, 255, 241, 0.08);
  border: solid 2px #231f1f28;
  border-radius: 2%;

  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  gap: 10px;
  padding: 2% 1% 2% 1%;

  position: relative;
}

.additional_extra_input_fields {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
.additional_extra_details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 5px;
}

.add_extra_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.extras_table_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: auto;
  max-height: 200px;
  overflow-y: auto;
}

.input_label_description {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: var(--theme-font-size-medium);
  font-weight: bold;
}

/*
    @media screen and (max-width: 1000px) {
      .container {
        grid-template-columns: 1fr;
      }
    
      .container_item {
        width: 100%;
        height: 50%;
      }
    }
    */

.image_container {
  height: 100px;
  width: 100px;

  border: 2px solid #231f1f;
  cursor: pointer;
}

.main_image {
  height: 100%;
  width: 100%;

  max-height: 160px;
  max-width: 220px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.main_video {
  height: 100%;
  width: 100%;

  max-height: 300px;
  max-width: 400px;
}

.product_description_output {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  align-self: center;

  gap: 5px;

  width: 70%;
  font-size: 1.5rem;
}

.submit_reset_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 2%;
}

.submit_reset_div Button {
  margin: 0 5% 0 5%;
}
