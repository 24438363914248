.pagebody {
  padding: 10px 0px 10px 0px;
  margin: 0px;

  width: 100vw;
  min-width: 200px;
  min-height: 100vh;
  size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*display: grid;
  gap: 20px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-content: space-around;
  align-items: center;
  align-self: center;*/

  font-family: "Changa", cursive;
  font-weight: 300;

  /*background-color: FBFBFB;*/
  /*background-color: F7F7F7;*/
  background-image: url("bg2.jpg");

  /*background-image: linear-gradient(
      45deg,
      transparent 20%,
      rgb(93, 210, 93) 25%,
      transparent 12%
    ),
    linear-gradient(-45deg, transparent 20%, green 25%, transparent 25%),
    linear-gradient(-45deg, transparent 75%, green 80%, transparent 0),
    radial-gradient(rgb(128, 128, 128) 2px, transparent 0);
  background-size: 20px 20px, 20px 20px;*/
}

.pageTitle {
  font-family: "Changa", cursive;
  font-weight: 500;
  text-align: center;
  width: auto;
  margin: auto;
  color: rgb(128, 31, 4);

  background-color: rgb(0, 121, 122, 0.2);
}

.about_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 10px;
  gap: 10px;

  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.626);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-size: 1.3rem;

  border: 1px solid #f6dede95;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.63);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  padding: 10px;
}

._ar {
  direction: rtl;
  text-align: right;
}
._en {
  direction: ltr;
  text-align: left;
}

.products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-self: center;
  width: 90%;
}

.products_management_container {
  margin-top: 1rem;
  width: 100vw;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tabs_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  font-size: 2rem;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.mainGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.2em;

  align-items: center;
  align-self: center;
  margin: auto;
  width: 60%;
}

.mainIcons {
  width: 90%;
  margin: auto;
}

.infoDiv {
  margin: auto;
  width: 95%;
  min-width: 400px;
  max-width: 90vw;
  border: 3px solid rgb(0, 121, 122, 0.3);
  padding: 10px;
}

.qrList {
  display: flex;
  flex-direction: column;
}

.button {
  /*background-color: rgb(55, 254, 141);
  border: solid rgb(144, 243, 180);*/
  background-color: #d39a59;
  border: rgb(202, 100, 71);
  border-radius: 1em;
  font-family: "Changa", cursive;
  font-weight: 400;
  font-size: large;
  color: white;

  margin: auto;
  width: 80vw;
  max-width: 400px;
}

.button:hover {
  background-color: rgb(202, 100, 71);
  cursor: pointer;
}

.qrList .button {
  margin: auto;
  margin-bottom: 1rem;
}

.scanner {
  size: 100%;
  margin: auto;
}

.qrString {
  margin: auto;
}

.alert {
  width: 80vw;
  max-width: 900px;
}

@media only screen and (max-width: 640px) {
  .mainGrid {
    margin: auto;
    width: 50%;

    grid-template-columns: 1fr;
    grid-gap: 0.3em;
  }

  .mainIcons {
    width: 50%;
    margin: auto;
  }
}
