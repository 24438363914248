.productCard_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px 0px 5px 0px;
  border: 1px solid #f6dede95;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.63);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  align-self: center;
}

.productCard_card {
  width: 100%;
  height: fit-content;
  /*max-width: 600px;*/
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center; /* Center vertically */
}

.productCard_card a {
  display: inherit;
  flex-direction: inherit;
  gap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: inherit;
  height: inherit;
  color: inherit;
  text-decoration: none;
}

.productCard_image {
  width: 100%;
  height: auto;
  max-width: 160px;
  max-height: 120px;
  border-radius: 5px;
  margin-bottom: 10px;

  /*flex: 1; */
}

.productCard_text {
  display: flex;
  flex-direction: column;
  align-self: center;

  width: 55%;
  /*flex: 1.6; */
}

.productCard_text_ar {
  direction: rtl;
  text-align: right;
}

.productCard_text_en {
  direction: ltr;
  text-align: left;
}

.productCard_text h3 {
  font-size: 1em;
  font-weight: bold;
}
.productCard_text p {
  font-size: 0.8em;
}

.productCard_add_delete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.productCard_add_options {
  width: 100%;
  height: fit-content;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}
.productCard_remove_list {
  width: 100%;
  height: fit-content;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.error_text {
  font-size: var(--theme-font-size-small);
  font-style: italic;
  color: rgb(199, 0, 0);
}
