.textfield {
  padding-right: 20px;
}

.inputDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-around;

  margin: auto;
  width: auto;
  /*max-width: 100vw;*/
  border: 5px solid rgb(0, 121, 122, 0.3);
  border-radius: 1rem;
  padding: 10px;
  background-color: rgb(255, 255, 255, 0.8);
}

.inputDiv_horizontalElement{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-around;

  margin: auto;
  width: auto;
  /*max-width: 100vw;*/
  border: 2px solid rgba(0, 122, 122, 0.162);
  border-radius: 1rem;
  padding: 10px;
}

.textfield {
  font-family: "Changa", cursive;
  font-weight: 300;
}

.selectLabels {
  font-family: "Changa", cursive;
  font-weight: 300;
}

.listDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}

.button {
  background-color: white;
}

.button:hover {
  background-color: gray;
}
