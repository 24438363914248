.formControl {
    font-family: 'Changa', cursive;
    font-weight: 500;  
}

.inputLabel {
    font-family: 'Changa', cursive;
    font-weight: 300;  
    font-weight: bold;

}

.selectedItem {
    font-family: 'Changa', cursive;
    font-weight: 300;  
}

.menuItem{
    font-family: 'Changa', cursive;
    font-weight: 300;   
}